.home-egame .egame-list {
    display: flex;
    flex-wrap: wrap;
}

.egame-list .game-item:hover .game-cover img {
    filter: drop-shadow(0 0 40px rgba(255,185,21,.8));
}

.home-egame .egame-list .game-item:not(:nth-child(6n)) {
    margin-right: 30px;
}

.home-egame .egame-list .game-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: calc(16.66% - 25px);
}

.home-egame .egame-list .game-item .game-cover img {
    display: block;
    width: 100%;
}

.home-egame .egame-list .game-item .game-name {
    color: #fff;
    font-size: 13px;
    padding-top: 5px;
    text-align: center;
    width: 100%;
}