.event-qmenu {
    animation: aqm 1.8s ease infinite;
    bottom: 10px;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, .8));
    left: 0;
    min-height: auto !important;
    position: fixed;
    transition: .3s ease;
    width: 110px;
    z-index: 21;
}

@keyframes aqm {
    0% {
        filter: drop-shadow(0 2px 8px rgba(0, 0, 0, .8));
    }

    55% {
        filter: drop-shadow(0 2px 6px #11d200);
    }

    70% {
        filter: drop-shadow(0 2px 6px #13eb00);
    }

    100% {
        filter: drop-shadow(0 2px 8px rgba(0, 0, 0, .8));
    }
}

.event-qmenu .handle {
    bottom: 5px;
    cursor: pointer;
    position: absolute;
    right: 2px;
    width: 30px;
}

.icon-left {
    position: absolute;
    left: 0;
    z-index: 99;
    color: #fff;
    font-size: 22px;
    /* margin-right: 15px; */
    bottom: 30px;
}

.event-qmenu>* {
    box-sizing: border-box;
}

/* .event-qmenu .handle::after {
    speak: none;
    color: rgb(255, 255, 255);
    content: "";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    left: 8px;
    line-height: 1;
    position: absolute;
    text-align: center;
    text-shadow: rgb(7, 82, 0) 0px -1px 3px;
    text-transform: none;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 30px;
    font-family: iconmps !important;
    font-variant: normal;
} */

.event-qmenu .handle {
    bottom: 5px;
    cursor: pointer;
    position: absolute;
    right: 2px;
    width: 30px;
}

.event-qmenu .cont {
    align-items: center;
    background-color: rgba(0, 0, 0, .8);
    border: 3px solid #0b8500;
    border-bottom-right-radius: 15px;
    border-left: 0;
    border-top-right-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 103px;
    padding: 10px;
    width: 80px;
}

.event-qmenu .cont .event-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    /* height: 60px; */
    justify-content: center;
    margin: 5px 0;
    position: relative;
    transition: .3s ease;
    width: 60px;
}

.event-qmenu .cont .event-item img,
.event-item-img {
    display: block;
    max-height: 100%;
    max-width: 100%;
}

.event-qmenu .cont .event-item img {
    display: block;
    max-height: 100%;
    max-width: 100%;
}


a img {
    border: none;
}


.side-menu {
    position: fixed;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    width: 70px;
    z-index: 19;
}

.side-menu li a {
    align-items: center;
    background: url(/public/assets/img/sidemenu-bg.png) 50%/contain no-repeat !important;
    color: rgba(255, 255, 255, .9);
    color: #000;
    display: flex;
    font-size: 12px;
    height: 70px;
    justify-content: center;
    line-height: 1.4;
    overflow: initial;
    text-decoration: none;
    transform: translateZ(0);
    transition: .3s ease;
    width: 70px;
}

.side-menu li a>h2:not(:empty) {
    background: #fff;
    box-sizing: border-box;
    color: #000;
    display: none;
    font-size: 14px;
    height: auto;
    padding: 4px 8px;
    position: absolute;
    right: 80px !important;
    text-decoration: none;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    width: auto;
}

.side-menu li a>p {
    display: none;
}