.footer-section {
    background: #212121;
    color: #fff;
    position: relative;
}

.footer-section .footer-feature {
    background: #080808;
    padding: 68px 0;
}

.footer-section .footer-feature {
    display: none;
}


.footer-section .footer-main {
    display: flex;
}

.footer-section>* {
    padding: 30px 0;
}

.footer-section .footer-main>div {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.footer-section .footer-main .footer-head {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 32px;
}

.footer-section .footer-main .footer-links {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer-section .footer-main .footer-links>a {
    color: #9f9f9f;
    cursor: pointer;
    font-size: 13px;
    text-decoration: none;
}

.footer-section .footer-main>div img[alt="18+"] {
    opacity: 0;
}

.footer-section .footer-provider {
    border-top: 1px solid #3a3a3a;
}

.footer-section .footer-provider .provider-list-box {
    gap: 10px;
    justify-content: center;
}

.provider-list-box {
    display: flex;
    flex-wrap: wrap;
}

.footer-section .footer-provider .provider-list-box {
    gap: 10px;
    justify-content: center;
}

.footer-section .footer-provider .provider-list-box>div {
    filter: none;
}

.provider-list-box img {
    display: block;
    height: 42px;
    margin-bottom: 2px;
    margin-right: 20px;
}