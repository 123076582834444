.home-title {
    align-items: center;
    display: flex;
    gap: 8.3px;
    margin: 18px 0;
}


.home-title {
    align-items: center;
    display: flex;
    gap: 8.3px;
    margin: 18px 0;
}

.home-title img {
    height: 35px;
    width: 35px;
}

.home-title .home-title-text {
    color: #f1c137;
    font-size: 20px;
    font-weight: 700;
    height: 35px;
    line-height: 42px;
}

.home-recommend .recommend-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.home-recommend .recommend-list .recommend-item {
    background-color: #504c40;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .49);
    color: #a1a1a1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 114px;
    justify-content: center;
    padding: 0 30px;
    position: relative;
    width: 367px;
}

.home-recommend .recommend-list .recommend-item .recommend-title {
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
}

.home-recommend .recommend-list .recommend-item .recommend-desc {
    display: none;
}

.home-recommend .recommend-list .recommend-item .recommend-hot-icon {
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.recommend-item .recommend-cover.egame {
    background-image: url(/public/assets/img/home-egame-front.png);
    background-position: right 8%;
    background-repeat: no-repeat;
    background-size: auto 180%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100% !important;

}

.recommend-item .recommend-cover.fishing {
    background-image: url(/public/assets/img/home-mpg-front.png);
    background-position: right 8%;
    background-repeat: no-repeat;
    background-size: auto 180%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100% !important;

}
.recommend-item .recommend-cover.live {
    background-image: url(/public/assets/img/home-live-front.png);
    background-position: right 8%;
    background-repeat: no-repeat;
    background-size: auto 180%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100% !important;

}