@font-face {
    font-family: "DIN";
    src: url(https://www.i1peraaff.com/static/media/DINAlternateBold.e16cdf81..otf);
}

.main-home {
    background-image: url(/public/assets/img/home-bg.jpg);
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    padding: 100px 0 50px;
    position: relative;
    /* overflow-x: hidden; */
}

.marqueediv {
    position: absolute;
    top: 45px;
}

.marquee-news {
    background-color: #73622f;
    border: 2px solid #8e7733;
    border-radius: 50px;
    margin: 0 auto;
    /* padding: 10px; */
    width: 1250px !important;
    position: relative;
}

.marquee-news .marquee-wrap {
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    margin: auto;
    position: relative;
    width: 100%;
    /* display: flex;
    align-items: center; */
}

.news {
    color: #fff;
    font-size: 19px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    position: absolute;
    top: 0;
}

.marquee-news .marquee-wrap .visible-area {
    height: 100%;
    margin-left: 36px;
    overflow: hidden;
    position: relative;
    /* width: 100%; */
    overflow: hidden;

}

.marquee-news .news-title {
    align-items: center;
    background: url(/public/assets/img/icon-speaker.png) -19px -15px no-repeat;
    display: flex;
    font-size: 13px;
    height: 36px;
    justify-content: center;
    width: 36px;
    position: absolute;
    top: 0;
}

.marquee-news .marquee-wrap .marquee-item {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    margin-right: 300px;
}

.marquee-news .marquee-wrap .marquee-content {
    position: absolute;
    white-space: nowrap;
    padding-left: 100vw;
}

.marquee-news .marquee-wrap .marquee-content.scrollLeft {
    animation: scrollLeft linear infinite;
    animation-delay: 2s;
    animation-duration: 22s;
}

@keyframes scrollLeft {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}

.home-jackpot .jackpot-wrap {
    background: url(/public/assets/img/jp-bg.png) 50%/contain no-repeat;
    height: 476px;
    position: relative;
    width: 460px;
}

.jackpot-winner {
    width: 100%;
    height: 30% !important;
    display: flex;
    justify-content: space-between;

}

.home-recomend {
    height: 100% !important;
}

.jackpot-winner{
    height: 55vh !important;
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp {
    align-items: center;
    bottom: 10%;
    color: #f1c137;
    display: flex;
    flex-direction: column;
    font-weight: 900;
    gap: 2px;
    height: 18%;
    justify-content: center;
    left: 50%;
    position: absolute;
    transform: translateX(-49%);
    width: 67.8%;
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi {
    font-family: Helvetica;
    font-size: 40px;
    letter-spacing: 2px;
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi i {
    background-color: #313131;
    border: 2px solid #000;
    border-radius: 4px;
    font-family: DIN;

    font-size: 28px;
    margin: 0 1px;
    padding: 2px 3px;
    position: relative;
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi i:nth-of-type(2) {
    margin-right: 8px;
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi i:nth-of-type(2):after {
    content: ".";
}

.home-jackpot .jackpot-wrap .home-jp .home-jp-digi i:after {
    bottom: 0;
    margin-left: 6px;
    position: absolute;
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi i:nth-of-type(5) {
    margin-right: 8px;
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi i:nth-of-type(8) {
    margin-right: 8px;
}


.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi i:nth-of-type(5):after {
    content: ".";
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi i:nth-of-type(8):after {
    content: ".";
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-digi i:nth-of-type(8) {
    margin-right: 8px;
}

.jackpot-winner .home-jackpot .jackpot-wrap .home-jp .home-jp-btn {
    cursor: pointer;
    font-size: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
}

.winners {
    width: 100%;
    /* height: 100%; */
}

.winners .home-title {
    align-items: center;
    display: flex;
    gap: 8.3px;
    margin: 18px 0;
}

.latest-win {
    height: 76% !important;
    overflow: hidden;
    /* background-color: #000; */
}

.latest-content {
    height: 35% !important;
    overflow-y: hidden;
}

.slide-items {
    align-items: center;
    background: #504c40;
    border-radius: 12px;
    color: #9e9e9e;
    display: flex;
    font-weight: 700;
    height: 82px !important;
    justify-content: space-between;
    padding: 12px;
    width: 100%;
    margin: 0 auto;
}

.swiper-container {
    height: 57%;
}

.slide-items>* {
    align-items: center;
    display: flex;
    gap: 5px;
}

.user-avatar img {
    border-radius: 50%;
    display: block;
    height: 58px;
    width: 58px;
}

.slide-items .user-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 5px;
}

.slide-items .user-name {
    color: #fff;
    font-size: 14px;
}

.slide-items .right .prize {
    color: #f1c137;
    font-size: 16px;
}

.col-4 {
    height: 150vh;
}

.home-title .btn-more {
    align-items: center;
    background: transparent;
    color: #f1c137;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    gap: 5.5px;
    height: 35px;
    line-height: 35px;
    margin-left: auto;
    transition: .2s;
}

.home-title .btn-more:after {
    background: url(/public/assets/img/icon-more.png) 50%/contain no-repeat;
    content: "";
    height: 10px;
    width: 10px;
}


@media screen and (max-width: 1200px) {
    .marquee-news {
        background-color: #73622f;
        border: 2px solid #8e7733;
        border-radius: 50px;
        margin: 0 auto;
        padding: 10px;
        width: 1100px !important;
        position: relative;
    }
}