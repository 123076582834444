.main-pic {
    background-image: url(/public/assets/img/live/hl-via_casino.jpg);
}

.main-pic.sexybcrt{
    background-image: url(/public/assets/img/live/hl-sexybcrt.jpg);
}
.main-pic.dg{
    background-image: url(/public/assets/img/live/hl-dg.jpg);
}
.main-pic.wm{
    background-image: url(/public/assets/img/live/hl-wm.jpg);
}
.main-pic.ezugi{
    background-image: url(/public/assets/img/live/hl-ezugi.jpg);
}
.main-pic.mg{
    background-image: url(/public/assets/img/live/hl-mg.jpg);
}
.main-pic.sa{
    background-image: url(/public/assets/img/live/hl-sa.jpg);
}
.main-pic.venus{
    background-image: url(/public/assets/img/live/hl-venus.jpg);
}

.home-live-list .live-item:hover .main-pic {
    filter: brightness(120%);
}

.home-live-list {
    overflow: hidden;
}

 .home-live-list .live-item:first-child {
    margin-right: 26px;
}

 .home-live-list .live-item:first-child .main-pic {
    height: 402px;
    width: 402px;
}

.home-live-list .live-item .main-pic {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    cursor: pointer;
    height: 173px;
    position: relative;
    transition: .3s ease;
    width: 173px;
}


.home-live-list .live-item:first-child .main-pic .gp-logo {
    height: 180px;
}

.home-live-list .live-item .gp-logo {
    bottom: 0;
    filter: drop-shadow(0 5px 5px #000);
    height: 100px;
    width: auto;
    left: 5px;
    position: absolute;
}


.home-live-list .live-item .provider-name {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    padding: 10px 20px 0;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.home-live-list .live-item {
    float: left;
    margin-bottom: 22px;
}

.home-live-list .live-item:not(:nth-child(4n+1)) {
    margin-right: 26px;
}