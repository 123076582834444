.header-container {
    background: #171717;
    left: 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 20;
}

.main-wrap {
    align-items: center;
    display: flex;
    height: 68px;
    justify-content: space-between;
}

.header-container .main-wrap * {
    align-items: center;
    color: #c4c4c4;
    display: flex;
    font-size: 15px;
    gap: 15px;
}

.logo img {
    height: 45px;
    width: auto;
}

.main-wrap>.right {
    padding-top: 10px;
}

.login-wrap {
    align-items: center;
    display: flex;
    gap: 15px;
}

.login-wrap .input-wrap {
    position: relative;
}

.login-wrap .input-wrap input {
    background-color: #434343;
    border: 1px solid transparent;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    height: 35px;
    padding: 0 10px;
    transition: .2s;
    width: 150px;
}

.login-wrap .input-wrap.password input {
    padding-right: 60px;
}

.login-wrap .input-wrap.password>i {
    align-items: center;
    color: #c4c4c4;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    height: 35px;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: 0;
    width: 25px;
}

.login-wrap .input-wrap.password>i.forgot-password {
    font-size: 14px;
    font-weight: 700;
    right: 5px;
}

.login-wrap .input-wrap input:hover {
    border: 1px solid #f1c137;
}

.login-wrap .input-wrap input:focus {
    border: 1px solid #f1c137;
    outline: none;
}

.btn-wrap {
    position: relative;
}

.btn-wrap .btn-big.login {
    background: transparent;
    box-shadow: inset 0 0 0 2px #fff;
}


.btn-big.signup {
    background: #0b8500;
    border-radius: 100%;
    color: #fff;
}

.login-wrap .btn-big {
    align-items: center;
    /* background-image: linear-gradient(180deg, #efd068, #fedfa1 32%, #e5c155 66%, #b77e18); */
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .49);
    color: #000;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: bolder;
    height: 35px;
    justify-content: center;
    min-width: 105px;
    transition: .2s;
}

.lang {
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.lang .lang-toggle {
    align-items: center;
    color: #d5d5d5;
    display: flex;
    font-size: 12px;
    height: 40px;
    justify-content: flex-end;
}

.lang .lang-list-wrap {
    background-color: #000;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .5);
    color: #fff;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transform: translateY(-15px);
    transition: .3s ease;
    width: 130px;
    z-index: 999;
}

.header {
    background: linear-gradient(180deg, #78b33d, #0b8500);

}

.nav {
    display: flex;
    height: 100%;
    justify-content: space-around;
}

.nav .nav-item {
    color: #fff;
    font-weight: 700;
    gap: 3px;
    height: 100%;
    text-align: center;
    transition: .2s;
    width: 100%;
}

.nav .nav-item>a {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
}

.nav .nav-item>a:hover {
    color: #fff;
}

.nav .nav-item>a {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
}

.nav .nav-items>a {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
}

.nav .nav-item h3 {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    gap: 3px;
    text-transform: uppercase;
}

.nav .nav-items h3 {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    gap: 3px;
    text-transform: uppercase;
}

.nav .nav-item a:after {
    bottom: 0;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    width: 40px;
    background: #d0ad4a;
}



.nav .nav-item>a:hover:after {
    background: #d0ad4a;
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #fff;
}